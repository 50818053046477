<template>
  <div>
    <PageHeader title="Checklistoverzicht" />

    <div class="tw-px-2.5 tw-pt-5 tw-pb-16 tw-mx-auto">
      <FormulateForm
        :key="searchKey"
        v-model="filters"
        name="checklistOverview"
        debounce
        class="tw-pb-6 tw-px-2 tw-border-b"
        @submit="submit"
      >
        <div class="tw-flex tw-flex-col md:tw-flex-row">
          <div class="tw-grid md:tw-grid-rows-2 tw-gap-2">
            <div class="tw-grid md:tw-grid-flow-col tw-gap-2">
              <FormulateInput
                type="select"
                name="status"
                label="Status"
                :options="PROPERTY_STATUSES"
                :input-class="['tw-text-sm tw-border-none tw-h-8 tw-mt-0.5']"
                :label-class="['tw-text-xs']"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="select"
                name="unit_type"
                label="Unit"
                :options="UNIT_TYPES"
                :input-class="['tw-text-sm tw-border-none tw-h-8 tw-mt-0.5']"
                :label-class="['tw-text-xs']"
                outer-class="tw-m-0"
              />
              <div class="tw-border-r"></div>
              <FormulateInput
                type="select"
                name="checklist"
                label="Checklist"
                :options="categories"
                placeholder="Selecteer checklist"
                validation="required"
                :input-class="['tw-text-sm tw-border-none tw-h-8 tw-mt-0.5']"
                :label-class="['tw-text-xs']"
                outer-class="tw-m-0"
                @change="resetCategoryAndTemplate"
              />
              <FormulateInput
                type="select"
                name="category"
                label="Categorie"
                :options="subCategories"
                placeholder="Selecteer categorie"
                :input-class="['tw-text-sm tw-border-none tw-h-8 tw-mt-0.5']"
                :label-class="['tw-text-xs']"
                outer-class="tw-m-0"
                @change="resetTemplate"
              />
              <FormulateInput
                type="select"
                name="template_id"
                label="Taaktype"
                :options="todoTypes"
                :input-class="['tw-text-sm tw-border-none tw-h-8 tw-mt-0.5']"
                :label-class="['tw-text-xs']"
                outer-class="tw-m-0"
              />
              <div class="tw-border-r"></div>
            </div>

            <div class="tw-grid md:tw-grid-rows-2 tw-gap-2 tw-col-span-2">
              <div class="tw-flex tw-gap-2">
                <FormulateInput
                  type="checkbox"
                  name="is_published"
                  label="Enkel gepubliceerde panden"
                  :label-class="['tw-text-xs']"
                  outer-class="tw-m-0"
                />
                <FormulateInput
                  type="checkbox"
                  name="hide_finished"
                  label="Verberg afgewerkte checklists"
                  :label-class="['tw-text-xs']"
                  outer-class="tw-m-0"
                />
              </div>
              <div class="tw-flex tw-gap-4">
                <FormulateInput
                  type="submit"
                  input-class="tw-font-bold tw-text-sm hover:tw-text-opacity-80 tw-rounded tw-text-success"
                  outer-class="tw-m-0"
                >
                  <i class="fas fa-search" /> Zoeken
                </FormulateInput>
              </div>
            </div>
          </div>
          <div class="tw-grid md:tw-grid-cols-2 tw-gap-2 tw-flex-shrink-0">
            <FormulateInput
              type="autocomplete"
              auto-complete-type="office"
              :multiple="true"
              name="office"
              label="Kantoren"
              placeholder="Selecteer kantoren"
              validation="atleastOneOfficeOrCollaborator"
              :validation-rules="{ atleastOneOfficeOrCollaborator }"
              :validation-messages="{
                atleastOneOfficeOrCollaborator: 'Er is minimaal één kantoor of medewerker vereist.'
              }"
              :input-class="['tw-text-sm tw-border-none tw-h-8 tw-mt-0.5']"
              :label-class="['tw-text-xs']"
              outer-class="tw-m-0"
            />
            <FormulateInput
              type="autocomplete"
              auto-complete-type="collaborator"
              :multiple="true"
              name="collaborator"
              label="Medewerkers"
              placeholder="Selecteer medewerkers"
              validation="atleastOneOfficeOrCollaborator"
              :validation-rules="{ atleastOneOfficeOrCollaborator }"
              :validation-messages="{
                atleastOneOfficeOrCollaborator: 'Er is minimaal één kantoor of medewerker vereist.'
              }"
              :input-class="['tw-text-sm tw-border-none tw-h-8 tw-mt-0.5']"
              :label-class="['tw-text-xs']"
              outer-class="tw-m-0"
            />
          </div>
        </div>
      </FormulateForm>

      <template v-if="checklists.results.length">
        <div class="tw-py-2 tw-flex tw-flex-row tw-justify-end tw-gap-4 tw-border-b">
          <FormulateInput
            v-model="showOnlyAgent"
            type="toggle"
            name="only_agent"
            label="Alleen makelaar"
            outer-class="tw-m-0"
          />
          <FormulateInput
            v-model="showOnlySalesSupport"
            type="toggle"
            name="only_sales_support"
            label="Alleen sales support"
            outer-class="tw-m-0"
          />
          <FormulateInput
            v-model="includeNvt"
            type="toggle"
            name="include_nvt"
            label="Inclusief n.v.t."
            outer-class="tw-m-0"
          />
          <FormulateInput
            v-model="expandedTodos"
            type="toggle"
            name="expanded_todos"
            label="Toon details"
            outer-class="tw-m-0"
          />
        </div>

        <div class="tw-mx-2 tw-py-2 tw-hidden md:tw-grid tw-grid-cols-3 tw-gap-2">
          <h3
            v-for="(title, column) in CHECKLIST_COLUMN_TITLES"
            :key="column"
            class="tw-m-0"
          >
            {{ title }}
          </h3>
        </div>
      </template>

      <details
        v-for="property in checklists.results"
        :key="property.id"
        open
        class="tw-border-t"
      >
        <summary class="tw-my-1 tw-cursor-pointer">
          <div class="tw-inline">
            <router-link
              :to="{ name: 'PropertyChecklist', params: { id: property.id } }"
              class="tw-font-semibold tw-text-sm"
            >
              {{ property.reference }}
            </router-link>
            {{ property.address }}
            <span v-if="property.published" class="tw-align-baseline">
              <i class="far fa-globe" />
            </span>
          </div>
        </summary>
        <ChecklistKanban
          :entity="property"
          :show-category-name="true"
          :show-na="includeNvt"
          :expanded="expandedTodos"
          :show-only-agent="showOnlyAgent"
          :show-only-sales-support="showOnlySalesSupport"
        />
      </details>
      <div v-if="loading" class="tw-h-10 tw-my-8 tw-w-full tw-text-center">
        <i
          class="fal fa-spinner-third fa-spin tw-text-3xl"
        />
      </div>
      <InfiniteLoading
        v-else
        key="infiniteLoader"
        spinner="waveDots"
        @infinite="infiniteLoad"
      >
        <div slot="no-more"><!-- Empty div to not render anything --></div>
        <template #no-results>Geen checklists gevonden</template>
        <template #error="{ trigger }">
          Fout bij het laden van checklists,
          <button type="button" class="link tw-font-semibold" @click.prevent="trigger">
            probeer het opnieuw.
          </button>
        </template>
      </InfiniteLoading>
    </div>
  </div>
</template>

<script>
import merge from 'lodash/merge'
import InfiniteLoading from 'vue-infinite-loading'
import { CHECKLIST_COLUMN_TITLES } from '@/utils/helpers'

import PageHeader from '@/components/PageHeader.vue'
import ChecklistKanban from '@/components/properties/ChecklistKanban.vue'

import { PROPERTY_STATUSES } from '@/forms/selectOptions'
import { errorModal } from '@/modalMessages'
import { getCategories, getChecklistOverview } from '@/services/checklists'

export default {
  name: 'ChecklistOverview',
  components: {
    PageHeader,
    ChecklistKanban,
    InfiniteLoading
  },
  constants: {
    PROPERTY_STATUSES,
    CHECKLIST_COLUMN_TITLES,
    UNIT_TYPES: [
      { value: '', label: 'Selecteer unit' },
      { value: 1, label: 'WV nieuwbouw' },
      { value: 2, label: 'WV herverkoop' },
      { value: 3, label: 'WV verhuur' },
      { value: 4, label: 'BV industrieel tk' },
      { value: 5, label: 'BV industrieel th' },
      { value: 6, label: 'BV kantoren tk' },
      { value: 7, label: 'BV kantoren th' },
      { value: 8, label: 'BV winkels tk' },
      { value: 9, label: 'BV winkels th' },
      { value: 10, label: 'BV overnames' },
      { value: 11, label: 'WV beheer' }
    ]
  },
  data () {
    return {
      loading: false,
      searchKey: 'init',
      filters: {
        status: 2
      },
      categories: [],
      checklists: {
        count: null,
        next: null,
        previous: null,
        results: []
      },
      includeNvt: false,
      expandedTodos: false,
      showOnlyAgent: false,
      showOnlySalesSupport: false
    }
  },
  computed: {
    subCategories () {
      const checklist = this.filters.checklist
      const categories = [{ label: 'Selecteer categorie', value: '' }]
      if (!checklist?.categories) return categories
      return [
        ...categories, ...checklist.categories.map(category => {
          return {
            id: category.id,
            label: category.name,
            value: category
          }
        })
      ]
    },
    todoTypes () {
      const types = [{ label: 'Selecteer taaktype', value: '' }]
      const category = this.filters.category
      if (!category?.todos) return types
      return [
        ...types,
        ...category.todos.map(todo => {
          return {
            label: todo.title,
            value: todo.id
          }
        })
      ]
    }
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler (value) {
        this.clearValues()
        localStorage.setItem('checklist_filters', value?.filters)

        if (!value?.filters) return false

        const parsedValues = JSON.parse(value.filters)
        const mergedFilters = merge(this.filters, parsedValues)
        this.filters = Object.assign({}, this.filters, mergedFilters)
        this.queryChecklists()
      }
    }
  },
  created () {
    this.loadCategories()
  },
  methods: {
    atleastOneOfficeOrCollaborator ({ getFormValues }) {
      const values = getFormValues()
      if (values.checklist?.id && values.category?.id && values.template_id) return true
      return values.office?.length || values.collaborator?.length
    },
    resetCategoryAndTemplate () {
      this.filters.category = null
      this.resetTemplate()
    },
    resetTemplate () {
      this.filters.template_id = null
    },
    clearValues () {
      this.searchKey = Math.random() // Reset form and it's values
      this.filters = { status: 2 } // set filters to default value
      this.checklists = {
        count: null,
        next: null,
        previous: null,
        results: []
      }
    },
    parseParams ({ checklist, category, ...payload }) {
      // Checklist is actually just parent category that isn't really used in the filtering.
      const params = { ...payload }
      if (params.user) params.user = 1
      if (category) params.category_id = category.id
      params.office = params.office?.map(office => office.id) || []
      params.collaborator = params.collaborator?.map(collaborator => collaborator.id) || []
      return params
    },
    async loadCategories () {
      try {
        const response = await getCategories()
        this.categories = response.data.results?.map(category => {
          return { id: category.id, label: category.name, value: category }
        })
        return response
      } catch (error) {
        console.error(error)
        errorModal('Fout bij het laden van categorieën, probeer het opnieuw.')
      }
    },
    async loadChecklists (payload) {
      const response = await getChecklistOverview(payload)
      return response.data
    },
    async queryChecklists (data = this.filters) {
      this.loading = true
      const params = this.parseParams(data)
      const checklists = await this.loadChecklists({ params })
      this.checklists = checklists
      this.loading = false
      return checklists
    },
    async infiniteLoad ($infinite) {
      try {
        const next = this.checklists.next
        if (!next) {
          // No more data and state is loaded
          if (this.checklists.results.length || !this.$route.query?.values) $infinite.loaded()
          return $infinite.complete()
        }
        const checklists = await this.loadChecklists({ url: next })
        const results = [...this.checklists.results, ...checklists.results]
        this.checklists = { ...checklists, results }
        $infinite.loaded()
        return checklists
      } catch (error) {
        $infinite.error()
        console.error(error)
      }
    },
    async submit (filters) {
      filters.overview_category_id = filters.checklist.id
      try {
        const response = await this.$router.replace({ query: { filters: JSON.stringify(filters) } })
        return response
      } catch (error) {
        // To silence the duplicate navigation error
        return error
      }
    }
  }
}
</script>
